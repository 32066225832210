<template>
  <div class="row m-0 p-0">
    <div class="col-12">
      <v-card outlined class="mt-1">
        <v-card-title class="my-0 py-1">
          <p class="ms-1 my-0 fs-11pt f-raleway fw-700" v-c:P>
            <i class="btn-icon-left" v-i:duo#users#18 v-c:P></i>
            Setores
          </p>
        </v-card-title>
        <hr class="my-0 py-0 mx-2">
        <v-card-text class="">
          <v-list dense>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in setores" :key="i">
                <v-list-item-icon class="me-2 px-0">
                  <i class="btn-icon-left" v-i:duo#chalkboard-teacher#24></i>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="ms-0 ps-0">
                    <span class="f-raleway fs-11pt fw-400">
                      {{item.nome}}
                    </span>
                    <span class="ms-2">Responsáveis: </span>
                    <span v-for="pessoa in item.responsaveis">
                      <span class="ms-1 fw-300 rounded px-1" v-bg:S>{{pessoa}}</span>
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { notification } from '@/components/notification/notification.js'
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'
import { setores, pessoas } from '@/views/calendarios/dados.js'

export default {
  name: 'listresponsaveis',
  components: { dummy },
  computed: {
  },
  data () {
    return {
      tab: null,
      selectedItem: 0,
      setores: setores,
      pessoas: pessoas,
    }
  },
  methods: {

  }
}
</script>
